<template>
    <div>
        <Header />
        <div class="w-1/2 mx-auto mt-10 overflow-hidden p-3 pb-5 text-center">
            <!-- Display unknown error -->
            <div v-if="error" class="w-full mt-4">
                <img src="../../assets/bulk-broken.png" class="w-1/2 mx-auto">
                <div class="w-full mx-auto border relative border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-4 rounded">
                    <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
                    <button type="button" @click="this.$router.push('/')" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
                </div>
            </div>

            <label v-if="isVerifying" class="align-middle w-full items-center mt-20 block">
                <span class="pr-2 font-bold">Verifying Aadhar</span>
                <svg class="animate-spin inline ml-2 h-8 w-8 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> 
            </label>
             <label v-if="isVerified" class="align-middle w-full items-center mt-20 block font-bold text-2xl">
                <img src="../../assets/check.gif" class="w-56 mx-auto -mb-5 inline">
                <br>
                {{aadhaarVerifiedText}}
            </label>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Header from './Header.vue'

export default {
    name:'KycItem',
    components:{
        Header        
    },
    mounted: function(){
        this.verifyKyc()
    },
    data(){
        return {
            error:false,
            isVerifying:true,
            isVerified:false,
            aadhaarVerifiedText:null
        }
    },
    methods:{
        verified(data){
            this.isVerifying = false
            this.isVerified = true
            localStorage.aadhar_verified = data.kycVerified
            localStorage.token = data.token;
            localStorage.mobileNumber = data.name ? data.name : '+91 '+data.mobile_number;
            localStorage.skipMock = true;
            let _this = this 
            setTimeout(function(){
                _this.aadhaarVerifiedText = 'Aadhar verified'
            }, 6500)
            setTimeout(function(){
                _this.redirectToClaim()
            }, 8000)
        },
        redirectToClaim(){
            this.$router.push('claim')
        },
        verifyKyc(){
            var hasError = this.$router.currentRoute._value.query.error; 
            if(!localStorage.sid){
                return this.$router.push('/')
            }
            if(hasError){
                this.error = hasError
                this.isVerifying = false
                return
            }
            axios.get('verify-kyc/' + localStorage.sid)
            .then((response) => {
                if(response.data.kycVerified){
                    this.verified(response.data)
                }
            })
            .catch((error) => {
                this.isVerifying = false
                this.error = error.message
            })
        }
    }
}
</script>