
<template>
  <div v-if="shown" class="fixed bottom-0 left-0 text-right font-bold z-10 shadow-lg bg-white p-4 w-full">
    <label class="pr-5">
    Install SEDAX?
    </label>
    <button @click="installPWA" class="bg-purple-900 text-white shadow-lg py-2 px-4 rounded-xl font-bold ml-5">
      Install
    </button>
    <button @click="dismissPrompt" class="bg-purple-100 shadow py-2 ml-4 px-4 rounded-xl font-bold">
      Cancel
    </button>
  </div>
</template>

<script>
export default {
  data: () => ({
    shown: false,
  }),

  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.shown = true
    })
  },

  methods: {
    dismissPrompt() {
      this.shown = false
    },

    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
  }
}
</script>