<template>
    <div class="px-2">
        <div class="rounded-xl mt-10 w-full mb-6 border overflow-auto shadow">
            <table class="border-collapse table-auto w-full text-sm border-0">
                <thead>
                    <tr>
                    <th class="border-b border-grey-600 bg-gray-700 font-extrabold p-5 pl-8 text-gray-50 text-left text-md">Credential Type</th>
                    <th class="border-b border-grey-600 bg-gray-700 font-extrabold p-5 pl-8 text-gray-50 text-left text-md">Issuer</th>
                    <th class="border-b border-grey-600 bg-gray-700 font-extrabold p-5 pl-8 text-gray-50 text-left text-md">Issuance Date</th>
                    <th class="border-b border-grey-600 bg-gray-700 font-extrabold p-5 pl-8 text-gray-50 text-center text-md text-xl">...</th>
                    </tr>
                </thead>
                <tbody class="bg-white dark:bg-slate-800">
                    <tr v-if="loading">
                        <td colspan="4" class="text-center py-4">
                            <svg class="animate-spin h-6 w-6 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </td>
                    </tr>
                    <tr v-else v-for="(credential, index) in credentials" :key="index" class="hover:bg-purple-100">
                    <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 font-bold text-slate-500 dark:text-slate-400">{{credential['credentialSubject']['type']}}</td>
                    <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 font-semibold text-slate-500 dark:text-slate-400">Digilocker</td>
                    <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">{{new Date(credential['issuanceDate']).toUTCString()}}</td>
                    <td class="border-b border-slate-100 dark:border-slate-700 p-4 text-center pl-8 text-slate-500 dark:text-slate-400"><button @click="this.modalData = credential" class="px-3 py-2 rounded-lg border-2 hover:bg-gray-600 hover:text-white"><i class="bi bi-eye-fill align-middle"></i></button></td>
                    </tr>
                    <tr v-if="credentials.length == 0">
                        <td colspan="4" class="text-center py-10">
                            <i class="bi bi-folder2-open text-6xl text-gray-400"></i>
                            <div class="font-bold text-gray-700 py-2">No Credential Found!</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Display unknown error -->
        <div v-if="error" class="w-5/12 mx-auto border relative border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-4 rounded">
          <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
          <button type="button" @click="error=false" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
        </div>
        <Modal :data="modalData" @close-modal="closeModal" />
    </div>
</template>

<script>
import axios from "axios"
import Modal from "./modal"

export default {
    name:'CredentialList',
    components:{
        Modal        
    },
    mounted: function(){
        this.getCredentialList()
    },
    data(){
        return {
            error:false,
            credentials:[],
            modalData:null,
            loading:false
        }
    },
    methods:{
        signOut(){
            localStorage.clear()
            this.$router.push('/')
        },
        closeModal(){
            this.modalData = null
        },
        getCredentialList(){
            this.error = false
            this.loading = true
            axios.get('credentials', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then((response) => {
                this.loading = false
                this.credentials = response.data.credentials
            })
            .catch((error) => {
                if(error.response.status == 401){
                    this.signOut()
                    return
                }
                this.loading = false
                this.error = error.message
            })
        }
    }
}
</script>