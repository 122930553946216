<template>
    <div class="font-bold p-2 border-b shadow-sm w-full items-center select-none z-50 relative bg-white">
        <a href="./"><img src="../../assets/img/logo/android-chrome-192x192.png" width="45" class="inline-block ml-10 sm:ml-4"></a>
        <label @click="toggleDropDown" v-if="mobileNumber" class="float-right relative mr-3 md:mr-0 mt-2 flex cursor-pointer">
            <span @click="toggleDropDown" class="w-24 inline truncate text-gray-600 text-sm pt-1">{{mobileNumber}}</span>
            <span @click="toggleDropDown"><span class="rounded-3xl p-1 items-center tracking-widest ml-1 align-middle border-2 bg-purple-400 inline">🇮🇳</span></span>
            <button class="text-sm ml-2 hover:text-purple-700" :style="showDropDown?'transform:rotate(180deg)':''" type="button"><svg class="w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                <!-- Dropdown menu -->
                <div v-if="showDropDown" id="dropdown" class="z-10 w-36 mt-1 absolute border rounded-xl top-8 -right-1 bg-white divide-y divide-gray-100 shadow-sm dark:bg-gray-700">
                    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                        <li>
                            <a href="javascript:void(0)" @click="signOut()" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-purple-700">Sign out</a>
                        </li>
                        <li v-if="this.$route.name != 'Dashboard'">
                            <a href="javascript:void(0)" @click="this.$router.push('Dashboard')" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-purple-700">Dashboard</a>
                        </li>
                    </ul>
                </div>
        </label>
        <div v-if="globalMsg" class="w-72 z-50 p-4 global-msg rounded-xl fixed bottom-10 mx-auto left-0 right-0 bg-black shadow-2xl font-semibold text-md text-gray-50">
            <i class="mr-4 bi bi-check-circle-fill text-green-500"></i>{{globalMsg}}
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: 'HeaderItem',
    data(){
        return {
            mobileNumber : localStorage.mobileNumber ? localStorage.mobileNumber : false,
            showDropDown:false,
            globalMsg:null
        }
    },
    methods:{
        globalMessage(val) {
            this.globalMsg = val
            let _this = this
            setTimeout(function(){
                _this.globalMsg = null
            }, 3000)
        },
        toggleDropDown(){
            if(this.showDropDown){
                return this.showDropDown = false;
            }
            this.showDropDown = true;
        },
        signOut(){
            axios.post('logout', {}, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            localStorage.clear();
            this.$router.push('/');
        }
    }
}
</script>