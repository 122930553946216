import { createRouter, createWebHistory } from 'vue-router'
// import LandingPage from '../components/landing-page/Index.vue'
import Login from "../components/customer/Login.vue"
import Welcome from "../components/customer/Welcome.vue"
import Dashboard from "../components/customer/Dashboard.vue"
import Kyc from "../components/customer/Kyc.vue"
import Claim from "../components/customer/Claim.vue"
import PageNotFound from '../components/PageNotFound.vue'
import Home from '../components/Home.vue'
import QrcodeAuth from '../components/customer/QrcodeAuth.vue'

// Lazy load routes
// const qrscanner = () => import('../components/customer/QrScanner.vue')
// Define all the routes here
const routes = [
  // Landing Page Routes
  // { path: "/", name: "LandingPage", component: LandingPage },
  // Customer routes
  { path: "/login", name:'Login', component: Login },
  // { path: "/qrscanner", name: 'qrscanner', component: qrscanner },
  { path: "/", name:'Home', component: Home },
  { path: "/welcome", name:'Welcome', component: Welcome },
  { path: "/dashboard", name:'Dashboard', component: Dashboard },
  { path: "/kyc", name:'Kyc', component: Kyc },
  { path: "/claim", name:'Claim', component: Claim },
  { path: "/qrcode-auth", name:'QrcodeAuth', component: QrcodeAuth },

  // Vendor routes down here ...

  // 404 - route
  { path: '/:catchAll(.*)*', component: PageNotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicRoute = ["Login", "Home", "Kyc", "Claim", "QrcodeAuth"];
  if (!publicRoute.includes(to.name) && !localStorage.token) next({ name: 'Login' })
  else next()
})
  
  export default router