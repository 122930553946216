<template>
    <div>
        <Header />
        <div class="w-5/12 md:w-8/12 sm:w-11/12 mx-auto rounded-xl mt-10 overflow-hidden p-3 pb-5 mb-6 relative border">
            <div v-if="slideCount == 0" class="w-full slide-left">
                <div class="text-center font-extrabold font-sans text-lg md:text-xl pt-3 md:pt-5 -mb-5">Welcome Aboard Captain</div>
                <img src="../../assets/start.png" class="w-11/12 md:w-4/6 mx-auto">
                <div class="text-center font-extrabold font-sans -mb-8 -mt-2">Let's Get Started</div>
            </div>
            <div v-if="slideCount == 1" class="w-full slide-left">
                <div class="text-center font-extrabold font-sans text-lg md:text-xl pt-3 md:pt-5 -mb-5">Create Your Globally Recognized Digital Identity</div>
                <img src="../../assets/person.png" class="w-11/12 md:w-4/6 p-5 mx-auto">
                <div class="text-center font-extrabold font-sans -mb-8 -mt-2">Safe and Secure</div>
            </div>
            <div v-if="slideCount == 2" class="w-full slide-left">
                <div class="text-center font-extrabold font-sans text-lg md:text-xl pt-3 md:pt-5 -mb-5">A Futuristic Technology</div>
                <img src="../../assets/id-card.png" class="w-11/12 md:w-4/6 p-9 mx-auto">
                <div class="text-center font-extrabold font-sans -mb-8 -mt-2">Keep the power in your hand to share your identity</div>
            </div>
            <div class="text-center text-7xl pb-2 text-gray-300"><span :class="slideCount == 0 ? 'text-gray-500' : ''">.</span><span :class="slideCount == 1 ? 'text-gray-500' : ''">.</span><span :class="slideCount == 2 ? 'text-gray-500' : ''">.</span></div>
            <div class="text-center pt-8 pb-5">
                <button @click="countSlide()" class="py-2 text-white px-5 md:px-10 font-bold rounded-xl bg-gradient-to-tr from-purple-700 to-purple-400">{{this.slideCount == 2 ? "Let's Go" : "Next"}} <i v-if="slideCount < 2" class="bi ml-2 bi-arrow-right"></i></button>
            </div>
            <button v-if="slideCount < 2" @click="skip()" class="text-gray-400 absolute bottom-11 hover:text-purple-700 text-sm md:text-base right-5 font-extrabold">Skip <i class="bi bi-skip-end-fill text-xl -mb-2 align-middle"></i></button>
            <button v-if="slideCount > 0" @click="slideCount--" class="text-gray-400 absolute bottom-11 hover:text-purple-700 text-sm md:text-base left-5 font-extrabold"><i class="bi bi-caret-left-fill text-lg -mb-3 align-middle"></i></button>
        </div>
        <!-- Display unknown error -->
        <div v-if="error" class="w-5/12 mx-auto border relative border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-4 rounded">
          <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
          <button type="button" @click="error=false" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Header from './Header.vue'

export default {
    name:'WelcomeItem',
    components:{
        Header        
    },
    mounted: function(){
        if(localStorage.skipMock == 1){
            this.$router.push('Dashboard');
        }
    },
    data(){
        return {
            slideCount: 0,
            error:false
        }
    },
    methods:{
        countSlide(){
            if(this.slideCount < 2){
                return this.slideCount++;
            }
            this.skip();
        },
        signOut(){
            localStorage.clear();
            this.$router.push('/');
        },
        skip(){
            this.error = false;
            axios.post('skip-welcome-mock', {}, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then(() => {
                localStorage.skipMock = 1;
                this.$router.push('dashboard')
            })
            .catch((error) => {
                if(error.response.status == 401){
                    this.signOut();return;
                }
                this.error = error.message;
            })
        }
    }
}
</script>