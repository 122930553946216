<template>
    <div>
        <Header />
        <div class="w-full h-screen mx-auto overflow-hidden text-center bg-cover bg-center bg-no-repeat absolute top-6 left-0 z-0"  :style="{ backgroundImage: `url(${require('@/assets/qrcode.jpeg')})` }">
            <!-- Display unknown error -->
            <div v-if="error" class="w-1/2 sm:w-full px-4 mt-16 pt-10 mx-auto">
                <img src="../../assets/bulk-broken.png" class="w-80 p-5 mx-auto">
                <div class="w-full mx-auto border relative border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-4 rounded">
                    <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
                    <button type="button" @click="this.$router.push('dashboard')" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
                </div>
            </div>
            <div v-if="success" class="w-1/2 sm:w-full px-4 mt-16 pt-10 mx-auto">
                <div class="text-center mt-20"><i class="text-8xl text-green-500 bi bi-check2-circle"></i></div>
                <div class="w-full mx-auto border relative border-green-300 text-sm bg-green-50 text-green-400 p-3 mt-4 rounded">
                    <i class="mr-2 bi bi-exclamation-octagon"></i> Claim successfully added to your wallet.
                    <button type="button" @click="redirectToCredential()" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-green-700"></button>
                </div>
            </div>
            <div v-if="creatingClaim && !error && !success" class="text-center font-bold drop-shadow-lg text-gray-100 text-sm fixed top-0 left-0 right-0 bottom-0 m-auto" style="width:385px;height:350px">
                <div class="p-4 border rounded-xl mx-auto mb-6 bg-white">
                    <div class="text-center overflow-hidden rounded-lg" v-html="claim">
                    </div>
                </div>
                <i class="bi bi-info-circle-fill mr-2"></i>
                Scan the Qrcode from your sedax wallet to add this claim into your credential
            </div>
            <label v-if="!creatingClaim" class="align-middle w-full items-center mt-72 block">
                <span class="pr-2 font-bold text-white">Generating Claim</span>
                <svg class="animate-spin inline ml-2 h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> 
            </label>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Header from './Header.vue'

export default {
    name:'ClaimItem',
    components:{
        Header        
    },
    mounted: function(){
        if(!localStorage.aadhar_verified || !localStorage.sid){
            this.$router.push('/');
        }
        this.generateClaim();
    },
    data(){
        return {
            error:false,
            creatingClaim:false,
            claim:null,
            claimVerified:false,
            success:false,
            interval:null
        }
    },
    methods:{
        generateClaim(){
            this.error = false
            this.creatingClaim = false
            axios.get('generate-claim', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then((response) => {
                this.creatingClaim = true;
                if(response.data.message){
                    this.claimVerified = true
                    this.success = response.data.message
                    this.redirectToCredential()
                    return
                }
                this.claim = response.data
                this.pollingClaimVerification()
            })
            .catch((error) => {
                this.creatingClaim = false;
                this.error = error.message;
            })
        },
        redirectToCredential(){
            // if(timeout){
            //     var _this = this;
            //     setTimeout(function(){
            //         _this.redirectToCredential(false)
            //     }, 5500)
            //     return
            // }
            localStorage.setItem("showCredential", true)
            this.$router.push('dashboard')
        },
        pollingClaimVerification(){
            let _this = this
            this.interval = setInterval(function(){
                _this.verifyClaim()
            }, 2000)
        },
        verifyClaim(){
            axios.get('verify-claim', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then((response) => {
                if(response.data.success){
                    clearInterval(this.interval)
                    this.success = response.data.success
                    this.claimVerified = true
                    // this.redirectToCredential()
                }
            })
        }
    }
}
</script>