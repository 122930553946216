<template>
    <div class="overflow-hidden w-1/2 sm:w-11/12 mx-auto mt-5">
        <!-- Display unknown error -->
        <div v-if="error" class="text-center">
            <img src="../../assets/bulk-broken.png" class="w-1/2 mx-auto">
            <div class="w-100 mx-auto border relative border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-4 rounded">
            <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
            <button type="button" @click="error=false" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
            </div>
        </div>
    <div v-if="!restart" class="w-100 mx-auto mt-10 mb-10">
    <h3 class="mb-8 font-bold text-xl">Setting Up Wallet</h3>
    <div class="flex">
        <div class="w-1/4 sm:w-1/2 relative">
            <span v-if="step == 0" class="flex h-4 w-4 relative ml-1 z-20 mt-2 -mb-4">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
            </span>
            <i v-else class="text-green-400 bg-white relative z-20 text-2xl bi bi-check-circle-fill"></i>
            <br><span :class="(step > 0 ? '' : 'text-gray-400')+' text-sm sm:text-xs sm:pr-1 font-bold p-3 pr-5 pl-0 inline-block'">Verifying user</span>
            <span class="bg-gray-300 border w-full inline-block z-10 absolute top-4 left-1 h-0.5"></span>
        </div>
        <div class="w-1/4 sm:w-1/2 relative">
            <span v-if="step < 1" class="flex h-4 w-4 relative ml-1 z-20 mt-2 -mb-4">
            <span class="relative inline-flex rounded-full h-4 w-4 bg-gray-300"></span>
            </span>
            <span v-else-if="step == 1" class="flex h-4 w-4 relative ml-1 z-20 mt-2 -mb-4">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
            </span>
            <i v-else class="text-green-400 bg-white relative z-20 text-2xl bi bi-check-circle-fill"></i>
            <br><span :class="(step > 1 ? '' : 'text-gray-400')+' text-sm font-bold p-3 pr-5 sm:text-xs sm:pr-1 pl-0 inline-block'">Creating Wallet Address</span>
            <span class="bg-gray-300 border w-full inline-block z-10 absolute top-4 left-1 h-0.5"></span>
        </div>
        <div class="w-1/4 sm:w-1/2 relative">
            <span v-if="step < 2" class="flex h-4 w-4 relative ml-1 z-20 mt-2 -mb-4">
            <span class="relative inline-flex rounded-full h-4 w-4 bg-gray-300"></span>
            </span>
            <span v-else-if="step == 2" class="flex h-4 w-4 relative ml-1 z-20 mt-2 -mb-4">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
            </span>
            <i v-else class="text-green-400 bg-white relative z-20 text-2xl bi bi-check-circle-fill"></i>
            <br><span :class="(step > 2 ? '' : 'text-gray-400')+' text-sm font-bold p-3 pr-5 sm:text-xs sm:pr-1 pl-0 inline-block'">Creating identity</span>
            <span class="bg-gray-300 border w-full inline-block z-10 absolute top-4 left-1 h-0.5"></span>
        </div>
        <div class="w-1/4 sm:w-max relative">
            <span v-if="step < 3" class="flex h-4 w-4 relative ml-1 z-20 mt-2 -mb-4">
            <span class="relative inline-flex rounded-full h-4 w-4 bg-gray-300"></span>
            </span>
            <span v-else-if="step == 3" class="flex h-4 w-4 relative ml-1 z-20 mt-2 -mb-4">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
            </span>
            <i v-else class="text-green-400 bg-white relative z-20 text-2xl bi bi-check-circle-fill"></i>
            <br><span :class="(step > 3 ? '' : 'text-gray-400')+' text-sm font-bold p-3 sm:text-xs sm:pr-1 pr-5 pl-0 inline-block'">
                <span class="sm:hidden">Completed</span>
                <span class="hidden sm:block">Done</span>
            </span>
        </div>
    </div>
    <div v-if="step < 4">
        <img src="../../assets/scan-id.gif" class="w-full mx-auto -mt-20 sm:-mt-8">
    </div>
    <div v-else class="text-center pt-10 text-lg font-bold -ml-12">
        <div class="leading-10 pl-3">🎉 Congratulations!<br>Your wallet setup is completed now</div>
        <a href="./dashboard" class="py-2 rounded-xl inline-block bg-gradient-to-tr from-purple-700 to-purple-400 text-white font-semibold px-4 mt-5 mb-10">Go to dashboard <i class="bi text-xl bi-arrow-right-short ml-1"></i></a>
    </div>
    </div>
    <div v-else class="w-full text-center py-5">
        <a href="" class="py-2 rounded-xl inline-block bg-gradient-to-tr from-purple-700 to-purple-400 text-white font-bold px-4 mt-5 mb-10">Try again <i class="bi text-xl bi-arrow-counterclockwise align-top ml-1"></i></a>
    </div>
  </div>
</template>
<script>
import axios from "axios"
export default {
    name:'WalletSetup',
    data(){
        return {
            step:localStorage.step ? localStorage.step : 0,
            error:false,
            processCompleted:false,
            restart:false
        }
    },
    mounted(){
        var _this = this
        setTimeout(function(){
            if(localStorage.step){
                _this.createIdentity()
            }else{
                _this.step++
                _this.createPolygonWalletAddress()
            }
        },2000);
    },
    methods:{
        createPolygonWalletAddress(){
            axios.post('wallet-address', {}, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then(() => {
                var _this = this
                setTimeout(function(){
                    _this.step++
                    _this.createIdentity()
                },2000)
            })
            .catch((error) => {
                if(error.response.status == 401){
                    localStorage.clear()
                    this.$router.push('/')
                    return
                }
                this.error = error.message
                this.restart = true
            })
        },
        createIdentity(){
            axios.post('create-identity', {}, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then((response) => {
                var _this = this
                if(response.data){
                    _this.step++
                    setTimeout(function(){
                        _this.step++
                    },3000)
                }
            })
            .catch((error) => {
                if(error.response.status == 401){
                    localStorage.clear()
                    this.$router.push('/')
                    return
                }
                this.error = error.message
                this.restart = true
            })
        }
    }
}
</script>