<template>
    <div>
        <Header />
        <div class="w-full h-screen mx-auto overflow-hidden text-center bg-cover bg-center bg-no-repeat absolute top-6 left-0 z-0"  :style="{ backgroundImage: `url(${require('@/assets/qrcode.jpeg')})` }">
            <!-- Display unknown error -->
            <div v-if="error" class="w-1/2 sm:w-full px-4 mt-16 pt-10 mx-auto">
                <img src="../../assets/bulk-broken.png" class="w-80 p-5 mx-auto">
                <div class="w-full mx-auto border relative border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-4 rounded">
                    <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
                    <button type="button" @click="this.$router.push('/')" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
                </div>
            </div>
            <div v-if="qrcodeData && !error && !verified" class="text-center font-bold drop-shadow-lg text-gray-100 text-sm fixed top-0 left-0 right-0 bottom-0 m-auto" style="width:230px;height:350px">
                <div class="p-4 border rounded-xl mx-auto mb-6 bg-white">
                    <div class="text-center overflow-hidden rounded-lg" v-html="qrcodeData">
                    </div>
                </div>
                <i class="bi bi-info-circle-fill mr-2"></i>
                Scan the Qrcode from your sedax wallet to start your aadhar verification
            </div>
            <label v-if="isLoading" class="align-middle w-full items-center mt-72 block">
                <svg class="animate-spin inline ml-2 h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> 
            </label>
             <label v-if="verified" class="align-middle w-full items-center mt-60 block text-white font-bold text-2xl">
                <i class="w-56 mx-auto bi bi-check-circle-fill text-yellow-300 mr-3 inline"></i> Authenticated<br>
                Redirecting to Aadhar verification...
            </label>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Header from './Header.vue'

export default {
    name:'KycItem',
    components:{
        Header        
    },
    mounted: function(){
        if(!localStorage.sid){
            return this.$router.push('/')
        }
        this.qrcode()
    },
    data(){
        return {
            error:false,
            verified:false,
            isLoading:false,
            qrcodeData:null,
            interval:null
        }
    },
    methods:{
        qrcode(){
            this.isLoading = true
            this.qrVerified = false
            axios.get('get-auth-qrcode/'+localStorage.sid)
            .then((response) => {
                this.qrcodeData = response.data
                this.isLoading = false
                this.startPoll()
            })
            .catch((error) => {
                this.isLoading = false
                this.verified=false
                this.error = error.message
            })
        },
        startPoll(){
            let _this = this
            this.interval = setInterval(function(){
                _this.poll()
            }, 2000)
        },
        poll(){
            axios.get('polling-verify-qrcode-auth/'+localStorage.sid)
            .then((response) => {
                if(response.data.url){
                    this.verified = true
                    setTimeout(function(){
                        window.location.href = response.data.url
                    }, 3000)
                }else if(response.data.kycVerified){
                    clearInterval(this.interval)
                    localStorage.aadhar_verified = response.data.kycVerified
                    localStorage.token = response.data.token;
                    localStorage.mobileNumber = response.data.name ? response.data.name : '+91 '+response.data.mobile_number;
                    localStorage.skipMock = true;
                    this.$router.push('claim')
                }
            })
            .catch((error) => {
                this.error = error.message
            })
        }
    }
}
</script>
<style scoped>
body{
    overflow:hidden
}
.bg-center{
    background-position: center;
    /* filter:hue-rotate(35deg) */
}
</style>