<template>
    <div class="home">
        <div class="sticky top-0 left-0 z-50 bg-transparent shadow-lg bg-white">
            <div class="flex w-full p-2 items-center">
                <div class="w-1/2">
                <!-- <h4 class="font-extrabold ml-1 text-purple-900 font-sans text-2xl">SEDAX</h4> -->
                <a href="./"><img src="../assets/img/logo/android-chrome-192x192.png" width="45" class="inline ml-12 sm:ml-2 mr-2"></a>
                </div>
                <div class="w-1/2 text-right" >
                    <a v-if="!auth" @click="this.$router.push('Dashboard');" class="py-2 px-3 cursor-pointer bg-purple-100 border border-purple-500 text-purple-700 hover:bg-purple-700 hover:text-white font-medium shadow rounded-xl">SignIn / SignUp</a>
                    <label v-else @click="toggleDropDown" class="float-right relative mr-3 md:mr-0 font-bold mt-2 flex cursor-pointer">
                        <span @click="toggleDropDown" class="w-24 inline truncate text-gray-600 text-sm pt-1">{{mobileNumber}}</span>
                        <span @click="toggleDropDown"><span class="rounded-3xl p-1 items-center tracking-widest ml-1 align-middle border-2 bg-purple-400 inline">🇮🇳</span></span>
                        <button class="text-sm ml-2 hover:text-purple-700" :style="showDropDown?'transform:rotate(180deg)':''" type="button"><svg class="w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                            <!-- Dropdown menu -->
                            <div v-if="showDropDown" id="dropdown" class="z-10 w-36 mt-1 absolute border rounded-xl top-8 -right-1 bg-white divide-y divide-gray-100 shadow-sm dark:bg-gray-700">
                                <ul class="py-1 text-sm text-gray-700 dark:text-gray-200 text-left" aria-labelledby="dropdownDefault">
                                    <li>
                                        <a href="javascript:void(0)" @click="signOut()" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-purple-700">Sign out</a>
                                    </li>
                                    <li v-if="this.$route.name != 'Dashboard'">
                                        <a href="javascript:void(0)" @click="this.$router.push('Dashboard')" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 hover:text-purple-700">Dashboard</a>
                                    </li>
                                </ul>
                            </div>
                    </label>
                </div>
            </div>
        </div>
         <div v-if="error" class="w-1/2 mx-auto border relative border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-5 mb-3 rounded">
            <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
            <button type="button" @click="error=false" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
        </div>
        <div class="flex sm:flex-col-reverse items-center border-gray-300 z-40 mx-5 sm:mx-0 mb-5 mt-20 sm:mt-0 rounded-3xl sm:rounded-none overflow-hidden" style="background-image: linear-gradient(-103deg, rgba(30, 8, 84, 1) 0%, rgba(127, 102, 187, 1) 100%);">
           <div class="text-white font-extrabold text-4xl sm:text-lg pl-5 sm:p-4 w-1/2 sm:w-full h-full leading-relaxed">
                An Era of Decentralised Identity<br>
                Store your Identity on Blockchain
                <br>
                <button @click="kyc()" class="py-2 rounded-xl bg-gradient-to-tr from-purple-900 to-purple-500 text-white border-2 border-purple-500 font-bold px-3 text-xl mt-5 inline-block" :disabled="isKycLoading">
                    <img src="../assets/aadhar.png" class="w-12 inline mx-auto">
                    <span class="align-middle ml-2">Verify Your Aadhar</span> 
                    <i v-if="!isKycLoading" class="bi text-3xl bi-arrow-right-short align-middle mt-1 ml-2"></i>
                    <svg v-else class="animate-spin inline ml-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg> 
                </button>
                <div class="flex mt-5">
                    <h3 class="font-bold font-sans text-xl sm:text-sm flex">Powered by <img src="../assets/polygon.png" class="mx-2 bg-black rounded-xl p-2 w-8 sm:w-6 sm:rounded-md sm:p-1"> polygon</h3>
                </div>
            </div>
           <div class="w-7/12 sm:w-full relative">
                <div class="absolute top-0 left-0 w-full h-full" style="background-image: linear-gradient(3deg, rgba(109, 40, 217, 0.2) 20%, rgba(109, 40, 217, 0.4) 50%);"></div>
                <!-- <img src="../assets/aadhar_home.png" class="w-full"> -->
                <video loop autoplay muted>
                    <source src="../assets/sedax.mp4" type="video/mp4">
                    Your browser does not support the video.
                </video>
            </div>
        </div>
        <!-- <div class="absolute top-0 right-0 triangle-right"></div> -->
        <div class="items-center flex sm:flex-col p-10 sm:py-10 sm:px-2 mb-10">
            <div class="pt-20 sm:pt-1 w-1/2 sm:w-full">
                <img src="../assets/aadhar_home.png" class="w-full">
            </div>
            <div class="w-full font-medium text-gray-100 py-10 sm:p-3 leading-8 tracking-wide rounded-2xl mt-16 sm:text-justify">
                <h4 class="font-extrabold mb-5 border-b-4 border-gray-100 w-max mx-auto text-center text-3xl">
                    About us
                </h4>
                Sedax is a platform which allows user to share encrypted digital copy of gov issued ids like aadhaar to any service provider for KYC verification and record of exchange between 2 parties is recorded over blockchain based server, sharing digital copy of ids eliminates need of hard copy submission of ids of user which protect user data from exposer and leakage of data and protect users data from being misused by criminals to commit crimes like financial fraud and fake id forging.
            </div>
        </div>

        <div class="px-10 py-20 m-5 sm:px-4 sm:m-4 rounded-3xl" style="background-image: linear-gradient(103deg, rgba(30, 8, 84, 1) 0%, rgba(127, 102, 187, 1) 100%);">
            <div class="pb-10 sm:-mt-10 -m-4 mb-2 px-5 text-white">
                <h3 class="font-sans text-4xl sm:text-2xl"><b>Technology</b></h3>
                <p class="mt-3 text-lg sm:text-base">SEDAX provides the solution for Decentralised identity with scalability, ease of use and decentralized security.</p>
            </div>
            <div class="flex sm:flex-col">
                <div class="w-1/3 sm:w-full mr-5 sm:mr-0 bg-white rounded-3xl p-5">
                    <div class="overflow-hidden">
                        <img src="../assets/icon1.png" width="60" class="mb-3">
                        <b class="text-2xl font-extrabold">Digital Identity</b>
                        <p class="text-sm mt-4 leading-6 tracking-wide">The Decentralized Way
                            The main characteristics of blockchain technology and data management in blockchain networks are decentralization, immutability and transparency. Blockchain-based digital identities address some of the challenges that centralized or human-powered models currently face. Manipulation of data processing by majority voting achieved through multiple mechanisms (Proof of Work, Proof of Stake, etc.)
                        </p>
                    </div>
                </div>
                <div class="w-1/3 sm:w-full mr-5 sm:mr-0 ml-5 sm:ml-0 sm:mt-8 bg-white rounded-3xl p-5">
                    <img src="../assets/icon3.png" width="60" class="mb-3">
                    <b class="text-2xl font-extrabold">Self-sovereign identity</b>
                    <p class="text-sm mt-4 leading-6 tracking-wide">
                        Self-sovereign identity is a user-centric upgrade to decentralized identities, addressing the need for individuals to control their identities, share only the information they need with organizations and platforms, and not expose the rest. For organizations, this means that user consent is required to manipulate data, potentially reducing the risk exposed by centralized control.
                    </p>
                </div>
                <div class="w-1/3 sm:w-full ml-5 sm:ml-0 sm:mt-8 bg-white rounded-3xl p-5">
                    <img src="../assets/icon2.png" width="60" class="mb-3">
                    <b class="text-2xl font-extrabold">Security of Identity</b>
                    <p class="text-sm mt-4 leading-6 tracking-wide">
                        As public awareness of the importance of security and ethical handling of personal data grows, traditional centralized digital identity systems have tried new re-engineering methods. This trend is also supported by EU regulators with the release of the PSD2, GDPR and MiFID2 directives.
                    </p>
                </div>
            </div>
        </div>
        

        <div class="p-5 text-black bg-white text-center mt-20 md:leading-9">
           <span class="text-sm">&copy; Copyright 2022 SEDAX DATA SOLUTIONS PRIVATE LIMITED. All Rights Reserved</span><br>
           <small>Address- Varanasi, Uttar Pradesh, India, 221005</small><br>
           <div class="pt-3 md:pt-0">
           <small>Sedax.info is powered by <a href="https://thecodework.com">
           <img src="../assets/thecodework_logo.png" class="w-8 inline-block">
           TheCodeWork</a></small>
           </div>
        </div>

        
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "HomePage",
    data(){
        return {
            isKycLoading:false,
            error:false,
            qrcode:null,
            showDropDown:false,
            auth: localStorage.token ? localStorage.token : false,
            mobileNumber: localStorage.mobileNumber ? localStorage.mobileNumber : false
        }
    },
    methods:{
        kyc(){
            this.isKycLoading = true
            axios.get('start-kyc')
            .then((response) => {
                localStorage.setItem('sid', response.data.sid)
                this.$router.push('qrcode-auth')
            })
            .catch((error) => {
                this.isKycLoading = false
                this.error = error.message
            })
        },
        toggleDropDown(){
            if(this.showDropDown){
                return this.showDropDown = false
            }
            this.showDropDown = true
        },
        signOut(){
            axios.post('logout', {}, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            localStorage.clear()
            this.auth = false
            this.$router.push('/')
        }
    }
};
</script>
<style scoped>
.home{
    background-image: linear-gradient(103deg, rgba(30, 8, 84, 1) 0%, rgba(127, 102, 187, 1) 100%) !important;
}
.triangle-right {
	width: 0;
	height: 0;
	border-top: 400px solid transparent;
	border-right: 800px solid rgb(63, 43, 116);
    border-bottom: 400px solid transparent;
    z-index:-1;
}
</style>