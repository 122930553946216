<template>
  <div class="overflow-hidden w-1/2 md:w-full sm:w-full mx-auto">
    <div class="slide-left mt-10 w-full border md:border-0 sm:border-0 p-5 md:p-0 bg-gray-50 md:bg-white sm:bg-white rounded-lg relative">
    <button @click="goToLogin()" class="bi bi-arrow-left text-black py-1 px-2 rounded-lg text-xl absolute md:left-2 left-6 md:-top-1 top-6 hover:bg-gray-100"></button>
      <h1 class="text-xl mt-4 font-bold text-center text-gray-700">Verify OTP</h1>
      <div class="text-center"><small class="text-gray-400">A six-digit OTP has been sent to +91 {{this.mobileNumber}}</small></div>
      <form @submit="submitOtp">
      <div class="row p-5 mt-5 mb-5 md:w-4/5 mx-auto">
        <div class="col-md-12">
          <label class="font-medium text-gray-600">Enter the otp sent to +91 <span class="text-gray-700 tracking-wide">{{this.mobileNumber}}</span></label>
          <div :class="'flex rounded-lg w-full bg-gray-100 overflow-hidden border '+(otpError ? 'border-red-400 text-red-500' : '')">
            <span class="pt-2 px-2"><i class="bi bi-phone"></i></span>
            <input type="text" @input="otpError=false" ref="otp" spellcheck="false" class="border-0 p-2 tracking-widest bg-gray-100 outline-none w-full" v-model="otp" placeholder="######">
          </div>
          <div v-if="otpError" class="text-red-500 text-sm pt-1">
            <i class="bi bi-info-circle mr-1"></i>
            {{otpError}}
          </div>
        </div>
        <div v-if="!allowResendOtp" class="text-gray-500 text-sm pt-2">Resend otp in <b>0 : {{timer}}</b></div>
        <div v-else class="pt-2 text-sm">Didn't receive otp? 
          <button :disabled="sendingOtp" type="button" @click="resendOtp" class="text-purple-600 rounded px-1 font-bold hover:bg-purple-600 hover:text-white disabled:opacity-60">
            <svg v-if="sendingOtp" class="animate-spin h-3 w-3 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Resend OTP</button>
        </div>
        <div class="col-md-12 pt-6 flex flex-row-reverse">
          <button :disabled="loading || otp.length != 6" class="items-center text-sm p-2 rounded-xl text-white font-bold flex bg-gradient-to-tr from-purple-800 to-purple-400 disabled:opacity-60">
            Verify
            <i v-if="!loading" class="bi bi-arrow-right ml-3 text-xs rounded-lg py-1 px-2 bg-purple-700"></i>
            <svg v-else class="animate-spin ml-3 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </button>
        </div>
        <!-- Display unknown error -->
        <div v-if="error" class="w-full border relative border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-4 rounded">
          <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
          <button type="button" @click="error=false" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
        </div>
    </div>
      </form>
  </div>
  </div>
</template>
<script>
import axios from "axios";
import "bootstrap-icons/font/bootstrap-icons.css";

export default {
  name: "VerifyOtp",
  props: ['mobileNumber', 'allowResendOtp'],
  data() {
    return {
      otp: "",
      otpError: false,
      error: false,
      loading: false,
      timer:60,
      sendingOtp:false,
    };
  },
  mounted: function(){   
       this.startCountDown();
       this.$refs.otp.focus()
  },
  watch:{
    allowResendOtp(newVal){
      if(!newVal){
        this.startCountDown();
        this.sendingOtp = false;
      }
    }
  },
  methods: {
    startCountDown(){
      this.timer = 60;     
      clearInterval(this.interval);       
      this.interval = setInterval(() => {
        if (this.timer === 0) {
          clearInterval(this.interval);       
          this.canResendOtp()   
        } else {
          this.timer--
        }   
      }, 1000);
    },
    goToLogin() {
      this.$emit('reloadLogin');
    },
    resendOtp(){
      this.sendingOtp = true;
      this.otpError = false;
      this.otp = '';
      this.$emit('resendOtp');
    },
    canResendOtp(){
      this.$emit('canResendOtp', true);
    },
    submitOtp: function (e) {
      e.preventDefault();
      this.otpError = false;
      this.error = false;
      this.loading = true;
      axios
        .post(
          "verify-otp", {'mobile_number':this.mobileNumber, 'otp':this.otp}
        )
        .then((response) => {
          this.loading = false;
          if(response.data.token){
            localStorage.token = response.data.token;
            localStorage.mobileNumber = response.data.name ? response.data.name : '+91 '+this.mobileNumber;
            localStorage.skipMock = response.data.skip_mock;
            localStorage.aadhar_verified = response.data.aadhar_verified
            this.$router.push('Dashboard');
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response && error.response.data.otp) {
            this.otpError = error.response.data.otp.join(' ');
            return;
          }
          this.error = error.message;
        });
    },
  },
};
</script>