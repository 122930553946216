<template>
    <div>
        <Header ref="headerRef"/>
        <!-- Display unknown error -->
        <div v-if="error" class="w-1/2 sm:w-11/12 mx-auto border relative border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-5 pr-4 text-left rounded">
            <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
            <button type="button" @click="error=false" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
        </div>
        <div v-if="!hasIdentity && !startBlockchainProcess">
            <div class="w-1/2 sm:w-11/12 rounded-xl border border-purple-300 bg-purple-50 mx-auto text-center mt-10 p-3 pb-10">
                <img src="../../assets/walk.png" class="w-1/2 mx-auto">
                <p class="w-10/12 sm:w-11/12 mx-auto font-bold text-left text-sm mt-5 mb-5 text-black bg-white rounded-xl border p-3 leading-6">
                    <i class="bi bi-info-circle px-2 py-1 bg-purple-100 rounded-lg mr-2"></i> Click below to create an identity and complete the wallet setup.
                </p>
                <button @click="start()" class="py-2 rounded-xl bg-gradient-to-tr from-purple-700 to-purple-400 text-white font-bold px-4 mt-5 inline-block" :disabled="isStartLoading">
                    <i v-if="!isStartLoading" class="bi bi-play-circle text-xl align-middle text-white mr-2"></i>
                    <svg v-else class="animate-spin inline mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg> 
                    <span class="align-middle ml-2 pr-4 text-xl">Start</span> 
                </button>
            </div>
        </div>
        <Wallet-Setup v-if="startBlockchainProcess" />
        <div v-if="hasIdentity && !startBlockchainProcess">
            <div class="w-11/12 pt-5 mx-auto">
                <div class="rounded-2xl text-white md:m-4 p-5 pr-0 bg-cover bg-no-repeat bg-purple-900" :style="{'background-image': 'url(' + require('../../assets/dashboard.jpeg') + ')'}">
                    <div class="text-white mb-4 flex">
                        <i class="bi bi-person-bounding-box text-4xl mr-4 ml-1"></i>
                        <label class="font-bold">
                            My Data<br>
                            <span class="text-xs align-top block text-purple-200">Recommended not to share with anyone</span>
                        </label>
                    </div>
                    <div class="w-full">
                        <div>
                            <div class="flex card-slider w-full overflow-x-auto">
                                <div class="bg-cover card-div bg-no-repeat h-44 relative rounded-lg shadow-2xl border border-purple-500" :style="{ backgroundImage: `url(${require('@/assets/card-1.jpeg')})` }">
                                    <div class="font-bold ml-3 pt-3 text-lg">My Credentials
                                        <i class="float-right z-10 cursor-pointer bi bi-shield-lock-fill align-top text-sm rounded-full border-2 text-white mr-2" style="padding:5px 6px 1px 5px"></i>
                                        <br>
                                        <span style="backdrop-filter: blur(10px)" class="text-sm -mt-4 align-top drop-shadow-2xl"><b>{{credentialCount}}</b> credential</span>
                                    </div>
                                    <button v-if="!showCredential" @click="showCredential=true" class="px-4 py-2 bg-purple-900 border border-purple-600 shadow-2xl rounded-lg font-bold text-sm absolute bottom-5 left-3">UnLock</button>
                                    <button v-else @click="showCredential=false" class="px-5 py-2 bg-purple-900 border border-purple-600 shadow-2xl rounded-lg font-bold text-sm absolute bottom-5 left-3">Lock</button>
                                </div>
                                <div class="bg-cover card-div bg-no-repeat ml-5 h-44 relative w-72 rounded-lg shadow-2xl border border-purple-500" :style="{ backgroundImage: `url(${require('@/assets/card-1.jpeg')})` }">
                                    <div class="font-bold ml-3 pt-3 text-lg">Identifier
                                        <i @click="showAadharRequest()" class="float-right z-10 cursor-pointer bi bi-shield-lock-fill align-top text-sm rounded-full border-2 text-white mr-2" style="padding:5px 6px 1px 5px"></i>
                                        <br>
                                        <span v-if="!identity" style="backdrop-filter: blur(10px)" class="text-md -mt-4 align-top drop-shadow-2xl">***************</span>
                                        <span e-else style="backdrop-filter: blur(10px)" class="text-sm block overflow-clip break-all w-full align-bottom drop-shadow-2xl pr-2">{{identity}}</span>
                                    </div>
                                    <button v-if="!identity" @click="fetchIdentity('showLoader')" class="px-4 py-2 bg-purple-900 border border-purple-600 shadow-2xl rounded-lg font-bold text-sm absolute bottom-5 left-3">
                                        <svg v-if="isIdentityLoading" class="animate-spin inline mr-1 align-top h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg> 
                                        UnLock
                                    </button>
                                    <button v-else @click="copyData('Identity copied', identity)" class="px-4 py-2 bg-transparent hover:bg-purple-900 border border-purple-600 shadow-2xl rounded-lg font-bold text-sm absolute bottom-5 left-3">Copy</button>
                                    <button v-if="identity" @click="identity=null" class="px-5 py-2 bg-purple-900 border border-purple-600 shadow-2xl rounded-lg font-bold text-sm absolute bottom-5 left-24">Lock</button>
                                </div>
                                <div class="bg-cover card-div bg-no-repeat ml-5 mr-5 h-44 relative w-72 rounded-lg shadow-2xl border border-purple-500" :style="{ backgroundImage: `url(${require('@/assets/card-1.jpeg')})` }">
                                    <div class="font-bold ml-3 pt-3 text-lg">Polygon Wallet Address
                                        <i @click="showAadharRequest()" class="float-right z-10 cursor-pointer bi bi-shield-lock-fill align-top text-sm rounded-full border-2 text-white mr-2" style="padding:5px 6px 1px 5px"></i>
                                        <br>
                                        <span v-if="!walletAddress" style="backdrop-filter: blur(10px)" class="text-sm -mt-4 align-top drop-shadow-2xl">################</span>
                                        <span v-else style="backdrop-filter: blur(10px)" class="text-sm block overflow-clip break-all w-full align-bottom drop-shadow-2xl pr-2">{{walletAddress}}</span>
                                    </div>
                                    <button v-if="!walletAddress" @click="fetchWalletAddress('showLoader')" class="px-4 py-2 bg-purple-900 border border-purple-600 shadow-2xl rounded-lg font-bold text-sm absolute bottom-5 left-3">
                                        <svg v-if="isWalletAddressLoading" class="animate-spin inline mr-1 align-top h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        UnLock
                                    </button>
                                    <button v-else @click="copyData('Wallet address copied', walletAddress)" class="px-4 py-2 bg-transparent hover:bg-purple-900 border border-purple-600 shadow-2xl rounded-lg font-bold text-sm absolute bottom-5 left-3">Copy</button>
                                    <button v-if="walletAddress" @click="walletAddress=null" class="px-5 py-2 bg-purple-900 border border-purple-600 shadow-2xl rounded-lg font-bold text-sm absolute bottom-5 left-24">Lock</button>                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
                <!-- <div class="flex overflow-auto w-100 my-5 px-4">
                    <div class="w-max rounded-xl p-3 text-gray-600 bg-red-200">
                        1 Dummy
                    </div>
                    <div class="w-max rounded-xl ml-4 p-3 text-white bg-blue-200">
                        2 Dummy
                    </div>
                    <div class="w-max rounded-xl ml-4 p-3 text-white bg-green-200">
                        3 Dummy
                    </div>
                    <div class="w-max rounded-xl ml-4 p-3 text-white bg-yellow-200">
                        4 Dummy
                    </div>
                </div> -->
                <div v-if="!showCredential" class="w-full py-4 md:p-4 mb-10">
                    <div id="qr" class="w-max sm:w-full mt-2 mb-10 mx-auto bg-purple-200 px-10 pt-7 sm:p-4 rounded-2xl text-center">
                        <div v-if="scan">
                            <div v-html="qrcode" style="width:200px" class="qrcode mx-auto mt-2 items-center text-center"></div>
                        </div>
                        <div v-else>
                            <div v-if="!acceptVerificationRequest">
                                <h4 class="mb-8 mt-2 font-extrabold">Scan claim Qrcode to add the credential or verifier Qrcode to verify your identity
                                    <!-- <br><span class="text-sm text-purple-600 mt-1 block">QRcode is valid for 2 minutes.</span> -->
                                </h4>
                                <img src="../../assets/qrcode.gif" class="mx-auto w-96 rounded-2xl">
                            </div>
                        </div>
                        <div :class="{ fullscreen: fullscreen }" ref="wrapper" @fullscreenchange="onFullscreenChange">
                            <div v-if="qrcodeError" class="w-full mx-auto border relative z-50 mt-5 -mb-5 border-red-300 text-sm bg-red-50 text-red-400 p-3 pr-4 text-left rounded">
                                <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.qrcodeError}}
                                <button type="button" @click="qrcodeError=false;scan=false;paused=false" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
                            </div>
                            <div v-if="message" class="w-full mx-auto border relative z-50 mt-4 border-green-300 text-sm bg-green-50 text-green-600 p-3 pr-4 text-left rounded">
                                <i class="mr-2 bi bi-check2-circle"></i>{{this.message}}
                                <button type="button" @click="message=null" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-gray-600"></button>
                            </div>
                            <qrcode-stream v-if="scan" :paused="paused" class="relative" @detect="onDetect" @camera-on="onCameraOn" @camera-off="onCameraOff" @error="onError">
                                <button @click="fullscreen = !fullscreen" class="fullscreen-button">
                                    <!-- <i class="bi bi-fullscreen bg-white text-2xl rounded p-2" alt="toggle fullscreen"></i> -->
                                </button>
                                <span v-if="!qrcodeError" @click="scan=false" class="cursor-pointer absolute right-5 top-5 text-white">
                                    <i class="bi drop-shadow-lg text-2xl bi-x-lg"></i>
                                </span>
                            </qrcode-stream>
                            <svg v-if="isQrcodeAuthLoading" class="animate-spin mx-auto h-6 w-6 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                        <a href="#qr" v-if="!scan && !acceptVerificationRequest" @click="scanQrcode()" class="p-3 transition-all mt-8 mb-10 inline-flex rounded-xl bg-purple-900 cursor-pointer shadow-lg mx-auto shadow-purple-500 hover:bg-purple-700 w-max text-white" :disabled="isQrcodeLoading">
                            <i v-if="!isQrcodeLoading" class="mr-2 ml-1 bi bi-qr-code-scan"></i>
                            <svg v-else class="animate-spin mr-2 ml-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span>Scan QRcode</span>
                        </a>

                        <div v-if="acceptVerificationRequest" class="text-center font-bold mt-10 text-gray-700">
                            {{verificationRequestMsg}}
                            <br>
                            <a @click="acceptVerification()" class="p-3 transition-all mt-8 mb-10 inline-flex rounded-xl bg-purple-900 cursor-pointer shadow-lg mx-auto shadow-purple-500 hover:bg-purple-700 w-max text-white" :disabled="isAcceptVerificationLoading">
                                <svg v-if="isAcceptVerificationLoading" class="animate-spin mr-2 ml-1 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <span>Accept Request</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <CredentialList />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from './Header.vue'
import WalletSetup from  './WalletSetup.vue'
import axios from "axios"
import { QrcodeStream } from 'vue-qrcode-reader'
import CredentialList from './CredentialList.vue'

export default {
    name:"DashboardItem",
    components:{
        Header,
        WalletSetup,
        QrcodeStream,
        CredentialList
    },
    data(){
        return {
            showStartForm : false,
            aadhar_verified: localStorage.aadhar_verified,
            isIdentityLoading:false,
            hasIdentity:false,
            identity:null,
            walletAddress:null,
            fullscreen: false,
            isAcceptVerificationLoading:false,
            error:false,
            qrcode:'',
            isQrcodeAuthLoading:false,
            scan:false,
            isQrcodeLoading:false,
            isStartLoading:false,
            showCredential:false,
            isCredentialLoading:false,
            startBlockchainProcess:false,
            paused: false,
            result: null,
            showScanConfirmation: false,
            isIdentityCopied:false,
            globalMsg:null,
            credentials:[],
            isWalletAddressLoading:false,
            qrcodeError:false,
            message:null,
            credentialCount:0,
            acceptVerificationRequest:false,
            verificationRequestMsg:null
        }
    },
    mounted:function(){
        if(localStorage.skipMock == 0){
            this.$router.push('Welcome')
        }
        
        if(localStorage.showCredential){
            this.showCredential = true
            localStorage.removeItem("showCredential");
        }

        this.fetchIdentity()
    },
    watch: {
        fullscreen(enterFullscreen) {
            if (enterFullscreen) {
                this.requestFullscreen()
            } else {
                this.exitFullscreen()
            }
        }
    },
    methods:{
        onCameraOn() {
            this.showScanConfirmation = false
        },
        copyData(msg, val){
            navigator.clipboard.writeText(val);
            this.$refs.headerRef.globalMessage(msg)
        },
        onCameraOff() {
            this.showScanConfirmation = true
        },

        onError: console.error,

        async onDetect(detectedCodes) {
            this.result = JSON.stringify(
                detectedCodes.map(code => code.rawValue)
            )
            this.paused = true
            this.verifyQrcode(this.result)
        },

        verifyQrcode(str){
            this.scan = false
            this.pause = true
            this.isQrcodeAuthLoading = true
            let val = JSON.parse(JSON.parse(str))
            // console.log(val)
            if(val.name && val.name == 'sedax'){
               this.qrcodeAuth(val.url);
            }else if(val.body && val.body.callbackUrl){
               this.qrcodeAuth(val.body.callbackUrl, true);
            }else{
                this.addClaim(val)
            }
            this.isQrcodeAuthLoading = false
        },
        qrcodeAuth(url, accept = false){
            axios.get(url, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then((response) => {
                this.scan = false
                
                if(response.data.message){
                    this.message = response.data.message
                }

                if(response.data.message && accept){
                    this.acceptVerificationRequest = true
                    this.verificationRequestMsg = response.data.description
                }
            })
            .catch((error) => {
                console.log(error);
                if(error.response.status == 401){
                    localStorage.clear();
                    this.$router.push('/');
                    return
                }
                if(error.response.data && error.response.data.message){
                    this.qrcodeError = error.response.data.message
                    return
                }
                this.qrcodeError = error.message;
            })
        },
        addClaim(val){
            axios.post("add-claim", val, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then((response) => {
                if(response.data.message){
                    this.message = response.data.message
                    this.scan = false
                }
            })
            .catch((error) => {
                if(error.response.status == 401){
                    localStorage.clear();
                    this.$router.push('/');
                    return
                }
                this.qrcodeError = error.message;
            })
        },
        acceptVerification(){
            this.isAcceptVerificationLoading = true
            axios.post("accept-verification-request", {}, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then((response) => {
                if(response.data.message){
                    this.message = response.data.message
                }
                this.isAcceptVerificationLoading = false
                this.acceptVerificationRequest = false
            })
            .catch((error) => {
                if(error.response.status == 401){
                    localStorage.clear();
                    this.$router.push('/');
                    return
                }
                this.isAcceptVerificationLoading = false
                this.qrcodeError = error.message;
            })
        },
        timeout(ms) {
            return new Promise((resolve) => {
                window.setTimeout(resolve, ms)
            })
        },
        onFullscreenChange() {
        // This becomes important when the user doesn't use the button to exit
        // fullscreen but hits ESC on desktop, pushes a physical back button on
        // mobile etc.

        this.fullscreen = document.fullscreenElement !== null
        },

        requestFullscreen() {
            const elem = this.$refs.wrapper

            if (elem.requestFullscreen) {
                elem.requestFullscreen()
            } else if (elem.mozRequestFullScreen) {
                /* Firefox */
                elem.mozRequestFullScreen()
            } else if (elem.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                elem.webkitRequestFullscreen()
            } else if (elem.msRequestFullscreen) {
                /* IE/Edge */
                elem.msRequestFullscreen()
            }
        },

        exitFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen()
            } else if (document.mozCancelFullScreen) {
                /* Firefox */
                document.mozCancelFullScreen()
            } else if (document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) {
                /* IE/Edge */
                document.msExitFullscreen()
            }
        },
        fetchWalletAddress(){
            this.isWalletAddressLoading = true
            axios.get('fetch-wallet-address', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then((response) => {
                if(response.data.walletAddress){
                    this.walletAddress = response.data.walletAddress
                }
                this.isWalletAddressLoading = false
            })
            .catch((error) => {
                if(error.response.status == 401){
                    localStorage.clear();
                    this.$router.push('/');
                    return
                }
                this.error = error.message;
                this.isWalletAddressLoading = false
            })
        },
        resetIdentity(){
            this.identity = null
        },
        fetchIdentity(showIdentity=false){
            if(showIdentity){
                this.isIdentityLoading = true
            }
            axios.get('fetch-customer-identity', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.token,
                }
            })
            .then((response) => {
                if(response.data.identity){
                    if(showIdentity){
                        this.identity = response.data.identity
                    }
                    this.hasIdentity = true
                    this.credentialCount = response.data.credentials
                }
                this.isIdentityLoading = false
            })
            .catch((error) => {
                if(error.response.status == 401){
                    localStorage.clear();
                    this.$router.push('/')
                    return
                }
                this.error = error.message
                this.isIdentityLoading = false
            })
        },
        scanQrcode(){
            this.scan = true
            this.pause = false
        },
        start(){
            this.isStartLoading = true
            let _this = this
            setTimeout(function(){
                _this.startBlockchainProcess = true
                _this.showStartForm = true
            },1500)
        },
        closeQrcode(){
            this.qrcode = '';
        }
    }
}
</script>
<style scoped>
.fullscreen {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.fullscreen-button {
  background-color: white;
  position: absolute;
  top: 65%;
  right: 15px;
}
.card-div{
    min-width: 18rem;
}
/* width */
.card-slider::-webkit-scrollbar {
  width: 1px;
}

/* Track */
.card-slider::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
.card-slider::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
}

/* Handle on hover */
.card-slider::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0);
}
</style>
<style>
.qrcode-stream-camera{
    max-width:100%
}
</style>