import { createApp } from 'vue'
import App from './App'
import './styles/app.css'
import router from './router'
import axios from 'axios'
import './registerServiceWorker'

if(process.env.NODE_ENV == 'production'){
    axios.defaults.baseURL = 'https://seda-b.thecodework.com/api';
}else{
    axios.defaults.baseURL = 'http://seda-backend.test/api';
}

createApp(App).use(router).mount('#app')

