<template>
  <div id="app">
      <router-view></router-view>
      <PWAPrompt></PWAPrompt>
  </div>
</template>
<script>
import PWAPrompt from './components/PWAPrompt.vue'

export default {
  components:{
        PWAPrompt        
    },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.slide-left{
  margin-left:100%;
  -webkit-animation: slide-left 0.5s forwards;
  animation: slide-left 0.5s forwards;
}
.slide-right{
  margin-left:-100%;
  -webkit-animation: slide-right 0.5s forwards;
  animation: slide-right 0.5s forwards;
}

@-webkit-keyframes slide-left {
    100% { margin-left: 0; }
}

@keyframes slide-left {
    100% { margin-left: 0; }
}

@-webkit-keyframes slide-right {
    100% { margin-left: 0; }
}

@keyframes slide-right {
    100% { margin-left: 0; }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>