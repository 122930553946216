<template>
  <div>
    <Header />
    <div v-if="!verifyOtp" class="w-1/2 sm:w-full mx-auto overflow-hidden">
    <div class="slide-right mt-10 w-full border sm:border-0 p-5 sm:p-2 bg-gray-50 sm:bg-white rounded-lg relative">
      <h1 class="text-xl mt-4 font-bold text-center text-poly">Welcome To SEDAX</h1>
      <label class="text-gray-400 w-full inline-block text-xs text-center">🇮🇳 India's First Blockchain Digital Identity</label>
      <form @submit="login">
      <div class="row px-5 sm:px-2 py-5 my-5 w-4/5 sm:w-full mx-auto">
      <div class="col-md-12 font-bold pb-5 uppercase">Quick Register | SignIn</div>
        <div class="col-md-12">
          <label>Enter your mobile number</label>
          <div :class="'flex rounded-lg w-full bg-gray-100 overflow-hidden border '+(mobileNumberError ? 'border-red-400 text-red-500' : '')">
            <span class="pt-2 px-2">+91</span>
            <input type="number" autofocus @input="mobileNumberError = false" class="border-0 p-2 bg-gray-100 outline-none w-full tracking-widest" v-model="mobileNumber" placeholder="eg:90566*****">
          </div>
          <div v-if="mobileNumberError" class="text-red-500 text-sm pt-1">
            <i class="bi bi-info-circle mr-1"></i>
            {{mobileNumberError}}
          </div>
        </div>
        <div class="col-md-12 pt-6 flex flex-row-reverse">
          <button :disabled="loading || String(mobileNumber).length != 10" class="items-center text-white p-2 rounded-xl text-sm font-bold hover:shadow-lg bg-gradient-to-tr from-purple-800 to-purple-400 flex disabled:opacity-60">
            <i v-if="!loading" class="bi bi-phone mr-2 py-1 px-2 rounded-lg bg-purple-700"></i>
            <svg v-else class="animate-spin mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Request OTP</button>
        </div>
        <!-- Display unknown error -->
        <div v-if="error" class="w-full relative border border-red-300 text-sm bg-red-50 text-red-400 p-3 mt-4 rounded">
          <i class="mr-2 bi bi-exclamation-octagon"></i>{{this.error}}
          <button type="button" @click="error=false" class="bi bi-x-lg p-2 rounded-lg absolute top-1 text-sm right-1 text-red-700"></button>
        </div>
    </div>
      </form>
    </div>
  </div>
  <verify-otp :allow-resend-otp="allowResendOtp" @can-resend-otp="canResendOtp" :mobile-number="mobileNumber" @reload-login="reloadLogin" @resend-otp="submitLogin" v-else />
  <div class="text-center pt-5 pb-5 text-gray-500 w-full text-xs">
    <a href="https://thecodework.com" class="inline-flex items-center">Powered by <img src="../../assets/thecodework_logo.png" class="align-middle ml-2 mr-2" width="25">TheCodeWork</a>
  </div>
  <div class="pt-4 text-center flex sm:flex-col md:w-1/2 mx-auto mb-10">
    <div class="grow p-5 p-3">
      <img src="../../assets/decentralize.png" class="mx-auto" width="60">
      <strong>Decentralised</strong>
      <p class="text-xs">Users are in control of their identity and personal data to enable social coordination and take back power from third parties.</p>
    </div>
    <div class="grow p-5 p-3">
      <img src="../../assets/lock.png"  class="mx-auto" width="60">
      <strong>Private by default</strong>
      <p class="text-xs">The user’s identity and data are natively protected by zero-knowledge protocols.</p>
    </div>
    <div class="grow p-5 p-3">
      <img src="../../assets/trust.png" class="mx-auto" width="60">
      <strong>Trust and reputation</strong>
      <p class="text-xs">
        Identity attributes can be expressed as claims which can be combined to create compound proofs.
      </p>
    </div>
  </div>
  </div>
</template>

<script>
import axios from "axios"
import "bootstrap-icons/font/bootstrap-icons.css"
import VerifyOtp from './VerifyOtp.vue'
import Header from './Header.vue'

export default {
  name: "LoginItem",
  props: {},
  components:{
    VerifyOtp,
    Header
  },
  data() {
    return {
      mobileNumber: "",
      mobileNumberError: false,
      error: false,
      loading: false,
      verifyOtp: false,
      allowResendOtp:false,
    };
  },
  mounted:function(){
    if(localStorage.token){
      this.$router.push('Welcome');
    }
  },
  methods: {
    reloadLogin(){
      this.verifyOtp = false;
      this.mobileNumber = '';
    },
    canResendOtp(bool = false){
      this.allowResendOtp = bool;
    },
    login: function (e) {
      e.preventDefault();
      this.submitLogin();
    },
    submitLogin(){
      this.mobileNumberError = false;
      this.error = false;
      this.loading = true;
      if(!this.mobileNumber){
        this.mobileNumberError = 'Mobile Number is Required';
        this.loading = false;
        return;
      }
      axios
        .get(
          "request-otp?mobile_number=" + this.mobileNumber
        )
        .then(() => {
          this.loading = false;
          this.allowResendOtp = false;
          this.verifyOtp = true;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response && error.response.data.mobile_number) {
            this.mobileNumberError = error.response.data.mobile_number.join(' ');
            return;
          }
          this.error = error.message;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>
